import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import styled from "@emotion/styled";
import { rhythm } from "utils/typography";
import { mq } from "utils/helper";
import { ThemeContext } from "context/ThemeContext";
import { LocalizationContext } from "context/LocalizationContext";
import useTranslations from 'hooks/useTranslations';
import { contentDecode } from "utils/content-decode";

import Layout from "components/layout";
import Seo from "components/seo";
import PreviewCompatibleImage from "components/PreviewCompatibleImage.js";

const P = styled.p({
  letterSpacing: "0.47px",
  lineHeight: "27px"
});

function DigitalMemory({ data }) {
  const [content, setContent] = useState(null);

  const tr = useTranslations();
  const { colors, contentComponents, strapi } = useContext(ThemeContext);
  const image = data.index.image;
  const text = data.index.content;

  useEffect(() => {
    async function decodeContent() {
      setContent(await contentDecode(text.data.content, colors, contentComponents, strapi.url));
    }
    decodeContent();
  }, []);

  return (
    <Layout>
      <Seo metadata={{ title: "Syrian Digital Memory "}} />
      <div>
        <div
          css={{
            paddingTop: rhythm(4),
            paddingBottom: rhythm(1),
            backgroundColor: colors.dark,
            backgroundImage:
              `linear-gradient(180deg, rgba(0,0,0,0.63) 0%, ${colors.primary_transparent} 100%)`,
            color: colors.light,
            [mq[1]]: {
              paddingTop: rhythm(1)
            }
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: rhythm(50),
              margin: "auto",
              [mq[3]]: {
                margin: `0 ${rhythm(1)} 0`
              },
              [mq[1]]: {
                display: "block",
              }
            }}
          >
            <h1
              css={{
                margin: 0,
                borderBottom: `6px solid ${colors.primary}`,
                [mq[1]]: {
                  margin: `${rhythm(2)} 0`
                }
              }}
            >
              {tr("Syrian Digital Memory")}
            </h1>
            <p
              css={{
                maxWidth: "560px",
                display: "block",
                opacity: 0.75,
                fontSize: "1.3rem",
                margin: 0
              }}
            >
              {tr("memory intro")}
            </p>
          </div>
          <PreviewCompatibleImage
            imageInfo={{
              image: image?.localFile,
              alt: "an image"
            }}
            imageStyle={{
              maxWidth: "960px",
              margin: `${rhythm(3)} auto`,
              [mq[3]]: {
                margin: `${rhythm(3)} ${rhythm(1)}`
              },
            }}
          />
        </div>
        <div
          css={{
            maxWidth: rhythm(50),
            margin: "6rem auto",
            [mq[1]]: {
              margin: rhythm(1)
            }
          }}
        >
          {content}
        </div>
      </div>
    </Layout>
  );
}

DigitalMemory.propTypes = {
  data: PropTypes.object
};

export default DigitalMemory;

export const pageQuery = graphql`
  query($locale: String!) {
    index: strapiPage(
      locale: {eq: $locale},
      slug: {regex: "/^memory/"},
      title: {eq: "index"}
    ) {
      content {
        data {
          content
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
